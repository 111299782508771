import { useStaticQuery, graphql } from "gatsby"

const useAirtableCSCData = () => {
  const data = useStaticQuery(graphql`
    {
      allAirtableCscPartnersWebsite(
        sort: { fields: data___Name, order: ASC }
        filter: { data: { Status: { eq: "Active" } } }
      ) {
        edges {
          node {
            data {
              Name
              Link
              Logo {
                localFiles {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return data.allAirtableCscPartnersWebsite.edges
}
export default useAirtableCSCData
import React from "react"
import cx from "classnames"
import Img from "gatsby-image/withIEPolyfill"
import styles from "./mediamusicblock.module.scss"

const MusicMediaBlock = ({ primary }) => {
  const {
    embed_location,
    block_content,
    block_embed,
    optional_image,
    optional_image_link,
  } = primary
  const RIGHT = embed_location === "Right"

  const Embed = () => (
    <div className={cx("columns", "large-4", styles.embedContainer)}>
      {block_embed.html && (
        <div
          className={cx(styles.embed)}
          dangerouslySetInnerHTML={{ __html: block_embed.html }}
        />
      )}
      {optional_image.fluid && (
        <a
          href={optional_image_link?.url || "#"}
          target="_blank"
          rel="noreferrer"
        >
          <Img
            className={cx(styles.embed_image)}
            fluid={optional_image.fluid}
            alt={optional_image.alt}
          />
        </a>
      )}
    </div>
  )

  const Content = () => (
    <div
      className={cx("columns", "large-8", styles.content)}
      dangerouslySetInnerHTML={{ __html: block_content.html }}
    />
  )

  return (
    <div className={cx("row", styles.container)}>
      {RIGHT ? (
        <>
          <Content />
          <Embed />
        </>
      ) : (
        <>
          <Embed />
          <Content />
        </>
      )}
    </div>
  )
}

export default MusicMediaBlock

import React from "react"
import Img from "gatsby-image"
import cx from "classnames"
import styles from "./fullwidthimage.module.scss"

/**
 * Takes in a fluid image, and fills it to the maximum container width
 */
const FullWidthImage = ({ primary }) => {
  return (
    <div className={cx("row", "justify-content-center", styles.fullwidthimage)}>
      <Img fluid={primary.image.fluid} width="100%" className={styles.image} />
    </div>
  )
}

export default FullWidthImage

import React from "react"

const Button = ({ primary }) => (
  <section
    style={{
      padding: 0,
      marginBottom: primary.margin_bottom,
      marginTop: `-${primary.margin_top}px`,
    }}
  >
    <div className="row">
      <a
        href={primary.button_link.url}
        className={`button ${primary.button_css_classes}  `}
        target="_blank"
        rel="noreferrer"
      >
        {primary.button_label.text}
      </a>
    </div>
  </section>
)

export default Button

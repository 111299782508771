import React, { useState } from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import cx from "classnames"
import styles from "./embedmodule.module.scss"

const EmbedCarousel = ({ items }) => {
  console.log(items)
  const [activeSlide, setActiveSlide] = useState(0)

  const renderThumbnails = () => {
    return null
  }

  return (
    <>
      <Carousel
        renderThumbs={renderThumbnails}
        selectedItem={activeSlide}
        onChange={setActiveSlide}
        className={styles.carousel}
        interval={8000}
        transitionTime={350}
        swipeScrollTolerance={5}
        emulateTouch
        stopOnHover
        useKeyboardArrows
        infiniteLoop
        swipeable
        autoPlay
      >
        {items.map(item => (
          <div key={item.embed_label}>
            <div
              className={styles.embed}
              dangerouslySetInnerHTML={{ __html: item.carousel_embed.html }}
            />
            <div
              className={cx(
                "row justify-content-center",
                styles.descriptionContainer
              )}
            >
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: items[activeSlide].description.html,
                }}
              />
            </div>
          </div>
        ))}
      </Carousel>
    </>
  )
}

export default EmbedCarousel

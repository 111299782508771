/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react"
import styles from "./mediumwidget.module.scss"

const MediumWidget = ({ primary }) => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const script = document.createElement("script")
      script.src = "https://medium-widget.pixelpoint.io/widget.js"
      script.async = true
      script.onload = () => {
        window.MediumWidget.Init({
          renderTo: "#medium-widget",
          params: {
            resource: primary.medium_account_link.url,
            postsPerLine: primary.postsPerLine
              ? primary.postsPerLine
              : window.innerWidth > 1100
              ? 3
              : window.innerWidth > 700
              ? 2
              : 1,
            limit:
              window.innerWidth < 700 ? 5 : primary.limit ? primary.limit : 9,
            picture: primary.image_size,
            fields: [
              primary.author && "author",
              primary.claps && "claps",
              primary.use_description && "description",
              primary.fans && "likes",
              primary.publication_date && "publishAt",
            ],
            ratio: "landscape",
          },
        })
      }
      document.body.appendChild(script)
    }
  }, [])

  return (
    <div className="row justify-content-center">
      <div className="medium-12 large-12 columns">
        <h2 className="stories-header">
          <a className={styles.blogLink} href={primary.medium_account_link.url}>
            {primary.medium_title.text}
          </a>
        </h2>
        <div id="medium-widget" />
      </div>
    </div>
  )
}

export default MediumWidget

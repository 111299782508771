import React from "react"
import cx from "classnames"
import styles from "./musicmediaembed.module.scss"

const MusicMediaEmbed = ({ primary }) => {
  const { content_embed } = primary
  return (
    <div className={cx("row", styles.release)}>
      <div className="columns large-12">
        <div
          className={styles.embed}
          dangerouslySetInnerHTML={{ __html: content_embed.html }}
        />
      </div>
    </div>
  )
}

export default MusicMediaEmbed

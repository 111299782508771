import React from "react"
import cx from "classnames"
import styled from "./embeddedcontent.module.scss"

/**
 * Renders an embed given a source and title
 */
const EmbeddedContent = ({ primary }) => {
  return (
    <div className={cx("row justify-content-center")}>
      <div className={cx("medium-12 large12 columns")}>
        <h2 className="stories-header">{primary.title.text}</h2>
      </div>
      <div className={cx("col-md-12", styled.embeddedcontent)}>
        <iframe
          title={primary.embed.title}
          src={primary.embed.embed_url}
          height="100%"
          width="100%"
          frameBorder="0"
          scrolling="no"
        />
      </div>
    </div>
  )
}

export default EmbeddedContent

import React from "react"
import cx from "classnames"
import styles from "./faq.module.scss"

/**
 * Creates consistent anchor tag IDs
 * @param {string} title FAQ Title
 * @returns anchor tag gID
 */
const createAnchorTagID = title => {
  return title.replace(" ", "-")
}

/**
 * Generates a table of contents
 * @param {Array} primary Primary slice content
 * @param {Array} items an array of FAQ items
 * @returns Table of contents
 */
const constructTableOfContents = (primary, items) => {
  // creates a unique list of group names
  const groups = new Set(
    items.map(item => (item.group_name ? item.group_name.trim() : null))
  )

  // creates a TOC "tree"
  return (
    <details className={cx("columns", "large-12", styles.toc)} open>
      <summary className={styles.summary}>{primary.toc_title.text}</summary>
      {[...groups].map(group => (
        <ul className={styles.toc_group}>
          <li className={styles.group_header}>
            <h5>{group}</h5>
          </li>
          {items
            .filter(item => item.group_name === group)
            .map(item => (
              <ul>
                <li>
                  <a href={`#faq-${createAnchorTagID(item.question.text)}`}>
                    {item.question.text}
                  </a>
                </li>
              </ul>
            ))}
        </ul>
      ))}
    </details>
  )
}

/**
 * Repeatable FAQ component
 */
const FAQ = ({ primary, items }) => {
  return (
    <div className={cx("row", styles.faq)}>
      {constructTableOfContents(primary, items)}
      {items.map(item => (
        <div className="columns large-12">
          <h2
            id={`faq-${createAnchorTagID(item.question.text)}`}
            style={{ marginBottom: "1em", fontSize: "20px" }}
          >
            {item.question.text}
          </h2>
          <div dangerouslySetInnerHTML={{ __html: item.answer.html }} />
          <hr />
        </div>
      ))}
    </div>
  )
}

export default FAQ

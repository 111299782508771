import React from "react"
import cx from "classnames"
import styled from "./applepodcastembed.module.scss"

/**
 * Renders an Apple Podcast Embed given a source url
 */
const ApplePodcastEmbed = ({ primary }) => {
  return (
    <div className={cx("row justify-content-center", styled.container)} id="podcasts">
      <div className={cx("medium-12 large12 columns")}>
        <h2 className="stories-header">{primary.podcast_title.text}</h2>
      </div>
      <div className={cx("col-md-12", styled.podcastembed)}>
        <iframe
          title={primary.podcast_title.text}
          allow="autoplay *; encrypted-media *; fullscreen *"
          frameBorder="0"
          height="450"
          style={{
            width: "100%",
            overflow: "hidden",
            background: "transparent",
          }}
          sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
          src={primary.embed_link.url}
        />
      </div>
    </div>
  )
}

export default ApplePodcastEmbed

import React from "react"
import styles from "./title.module.scss"

const Title = ({ primary }) => {
  const { text, bold } = primary
  return (
    <div className="row">
      <div className="large-12 columns">
        <h1 className={`${bold && styles.bold} ${styles.title}`}>
          {text.text}
        </h1>
      </div>
    </div>
  )
}

export default Title

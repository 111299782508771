import React from "react"

const RichText = ({ primary }) => {
  return (
    <div className="row">
      <div
        className="columns large-12"
        dangerouslySetInnerHTML={{ __html: primary.richtext.html }}
      />
    </div>
  )
}

export default RichText

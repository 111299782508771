import { useStaticQuery, graphql } from "gatsby"

const useAirtableWebsiteData = () => {
  const partners = useStaticQuery(graphql`
    {
      active:  allAirtableOrganizationsWebsite(
        sort: { fields: data___Name, order: ASC }
        filter: { data: { Website_Display: { eq: "Active" } } }
      ) {
        edges {
          node {
            data {
              Name
              Organization_Description
              Description__French_
              Link
              Logo {
                localFiles {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              P_T
              Website_Display
            }
          }
        }
      }
      historical: allAirtableOrganizationsWebsite(
        sort: { fields: data___Name, order: ASC }
        filter: { data: { Website_Display: { eq: "Historical" } } }
      ) {
        edges {
          node {
            data {
              Name
              Organization_Description
              Description__French_
              Link
              Logo {
                localFiles {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              P_T
              Website_Display
            }
          }
        }
      }
    }
  `)
  return {
    active: partners.active.edges,
    historical: partners.historical.edges,
  }
}
export default useAirtableWebsiteData
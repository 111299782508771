import React from "react"
import cx from "classnames"
import Img from "gatsby-image"
import styles from "./partners.module.scss"
import useAirtableWebsiteData from "../../hooks/use-airtable-website-data"
import useAirtableCSCData from "../../hooks/use-airtable-csc-data"

const isEn = process.env.GATSBY_BUILD_LANG !== "fr"

const Partners = ({ primary }) => {
  const {
    cscnc_heading,
    cscnc_description,
    historical_partners_heading,
  } = primary

  // I don't like doing this,
  // but we need to convert the province/territory code to its full name
  const codeToFullName = code => {
    const provinceEn = {
      AB: "Alberta",
      BC: "British Columbia",
      MB: "Manitoba",
      NB: "New Brunswick",
      NL: "Newfoundland and Labrador",
      NS: "Nova Scotia",
      NT: "Northwest Territories",
      NU: "Nunavut",
      ON: "Ontario",
      PEI: "Prince Edward Island",
      QC: "Québec",
      SK: "Saskatchewan",
      YT: "Yukon",
    }

    const provinceFr = {
      AB: "Alberta",
      BC: "Colombie-Britannique",
      MB: "Manitoba",
      NB: "Nouveau Brunswick",
      NL: "Terre Neuve et Labrador",
      NS: "Nouvelle Écosse",
      NT: "Territoires du Nord-Ouest",
      NU: "Nunavut",
      ON: "Ontario",
      PEI: "Île-du-Prince-Édouard",
      QC: "Québec",
      SK: "Saskatchewan",
      YT: "Yukon",
    }

    return isEn ? provinceEn[code] : provinceFr[code]
  }

  const parseLink = link => {
    if (link && link.startsWith("http")) {
      return link.substring(link.indexOf("//") + 2)
    }
    return link
  }

  const activePartners = useAirtableWebsiteData().active.map(edge => {
    const { data } = edge.node
    return {
      name: data.Name,
      logo: data.Logo.localFiles[0].childImageSharp.fluid,
      url: parseLink(data.Link),
      province: codeToFullName(data.P_T[0]),
      description: isEn
        ? data.Organization_Description
        : data.Description__French_,
    }
  })

  const historicalPartners = useAirtableWebsiteData().historical.map(edge => {
    const { data } = edge.node
    return {
      name: data.Name,
      logo: data.Logo.localFiles[0].childImageSharp.fluid,
      url: parseLink(data.Link),
      province: codeToFullName(data.P_T[0]),
      description: isEn
        ? data.Organization_Description
        : data.Description__French_,
    }
  })

  const cncsc = useAirtableCSCData().map(edge => {
    const { data } = edge.node
    return {
      name: data.Name,
      url: parseLink(data.Link),
      logo: data.Logo?.localFiles[0]?.childImageSharp?.fluid,
    }
  })

  return (
    <div className={styles.partners}>
      <section className={styles.generalPartners}>
        {activePartners.map(
          partner =>
            partner.logo && (
              <>
                <div className="row" key={partner.name}>
                  <div className="columns medium-5">
                    <a
                      href={`//${partner.url}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Img
                        className="no-a11y-transform"
                        fluid={partner.logo}
                        alt={partner.name}
                        role="img"
                        aria-label={
                          isEn
                            ? `Logo of ${partner.name}`
                            : `Le logo de ${partner.name}`
                        }
                      />
                    </a>
                  </div>
                  <div className="columns medium-6 medium-offset-1">
                    <p className={styles.partnerTitle}>
                      <a
                        href={`//${partner.url}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {partner.name}
                      </a>
                      {partner.province && (
                        <>
                          <br />
                          {partner.province}
                        </>
                      )}
                    </p>
                    <p className={styles.partnerDescription}>
                      {partner.description}
                    </p>
                  </div>
                </div>
                <hr />
              </>
            )
        )}
      </section>
      <section className={cx(styles.cscnc, "row")}>
        <div className="small-12 columns">
          <h2>{cscnc_heading.text}</h2>
          <div dangerouslySetInnerHTML={{ __html: cscnc_description.html }} />
        </div>
        <ul className="row">
          {cncsc.map(
            partner =>
              partner.logo && (
                <li className="columns small-12 medium-4">
                  <a href={`//${partner.url}`} target="_blank" rel="noreferrer">
                    <Img
                      className="no-a11y-transform"
                      fluid={partner.logo}
                      alt={partner.name}
                      role="img"
                      aria-label={
                        isEn
                          ? `Logo of ${partner.name}`
                          : `Le logo de ${partner.name}`
                      }
                    />
                  </a>
                </li>
              )
          )}
        </ul>
      </section>
      <section className={cx(styles.cscnc, "row", styles.historicalPartners)}>
        <div className="small-12 columns">
          <h2>{historical_partners_heading.text}</h2>
        </div>
        <ul className="row">
          {historicalPartners.map(
            partner =>
              partner.logo && (
                <li className="columns small-12 medium-4">
                  <a href={`//${partner.url}`} target="_blank" rel="noreferrer">
                    <Img
                      className="no-a11y-transform"
                      fluid={partner.logo}
                      alt={partner.name}
                      role="img"
                      aria-label={
                        isEn
                          ? `Logo of ${partner.name}`
                          : `Le logo de ${partner.name}`
                      }
                      imgStyle={{ objectFit: "contain" }}
                      style={{ height: "89px" }}
                    />
                  </a>
                </li>
              )
          )}
        </ul>
      </section>
    </div>
  )
}

export default Partners
